import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { ContactSupportButton } from "../../components/ContactSupportButton";
import { reclaim } from "../../reclaim-api";
import {
  ReclaimEventType,
  TimeScheme,
  TimeSchemeCreateRequest,
  TimeSchemeUpdateRequest,
} from "../../reclaim-api/TimeSchemes.types";
import { makeReloadableHook } from "../../utils/react";
import { useCallbackSafeRef } from "../useCallbackSafeRef";
import { useCaptureError } from "../useCaptureError";
import { useOurRouter } from "../useOurRouter";
import { useNotifications } from "../useNotifications";
import { filterTimeSchemesByEventType } from "../../reclaim-api/TimeSchemes.utils";
import { makeOrderedListComparitor } from "../../utils/sort";

const timeSchemesComparitor = makeOrderedListComparitor(["WORK", "MEETING", "PERSONAL"]);

export type UseTimeSchemesStateReturnType = {
  loading: boolean;
  error?: Error;
  timeSchemes?: TimeScheme[];
};

export type UseTimeSchemesActionsReturnType = {
  syncTimeSchemes: () => Promise<void>;
  createTimeScheme: (scheme: TimeSchemeCreateRequest) => Promise<TimeScheme | undefined>;
  updateTimeScheme: (schemeId: string, update: TimeSchemeUpdateRequest) => Promise<void>;
  reloadTimeSchemes: () => Promise<void>;
};

const getTimeSchemes = () => {
  return reclaim.timeSchemes.list();
};

const errorNotificationRefAtom = atom(false);

const { useHook, dataAtom } = makeReloadableHook<TimeScheme[], "timeSchemes">(getTimeSchemes, {
  dataKey: "timeSchemes",
  errorizer: "Failed to get time schemes",
});

export const useTimeSchemesState = (eventType?: ReclaimEventType): UseTimeSchemesStateReturnType => {
  const { timeSchemes, loading, error } = useHook();

  const [errorNotificationRef, setErrorNotificationRef] = useAtom(errorNotificationRefAtom);

  const { sendNotification } = useNotifications();

  const processedTimeSchemes = useMemo<TimeScheme[] | undefined>(
    () => (eventType ? filterTimeSchemesByEventType(eventType, timeSchemes) : timeSchemes)?.sort((a, b) => timeSchemesComparitor(a.policyType, b.policyType)),
    [timeSchemes, eventType]
  );

  let derivedError: Error | undefined;
  if (error) {
    derivedError = typeof error === "string" ? new Error(error) : error;
  }

  useEffect(() => {
    if (error && !errorNotificationRef) {
      setErrorNotificationRef(true);
      sendNotification({
        message: (
          <>
            There was an unexpected error loading your hours. Please{" "}
            <ContactSupportButton variant="link" lead="I'm having trouble loading my hours">
              contact support
            </ContactSupportButton>{" "}
            if this problem persists.
          </>
        ),
        type: "error",
      });
    }
  }, [error, errorNotificationRef, sendNotification, setErrorNotificationRef]);

  return { timeSchemes: processedTimeSchemes, loading, error: derivedError };
};

export const useTimeSchemesActions = (): UseTimeSchemesActionsReturnType => {
  const { captureError } = useCaptureError();
  const router = useOurRouter();

  const setTimeSchemes = useSetAtom(dataAtom);
  const { reload: reloadTimeSchemes } = useHook();

  const syncTimeSchemes = useCallbackSafeRef(async () => {
    try {
      const schemes = await reclaim.timeSchemes.list();
      await setTimeSchemes(schemes);
    } catch (cause) {
      captureError(new Error("Failed to sync Time Scheme list", { cause }), {
        severity: "error",
        statusCode: 5000,
      });
      void router.push("/500");
    }
  });

  const createTimeScheme = useCallbackSafeRef(async (scheme: TimeSchemeCreateRequest) => {
    try {
      const timeScheme = await reclaim.timeSchemes.create(scheme);
      const schemes = await getTimeSchemes();
      await setTimeSchemes(schemes);
      return timeScheme;
    } catch (cause) {
      captureError(new Error("Failed to create Time Scheme", { cause }), {
        severity: "error",
        statusCode: 5000,
      });
    }
  });

  const updateTimeScheme = useCallbackSafeRef(async (schemeId: string, update: TimeSchemeUpdateRequest) => {
    try {
      await reclaim.timeSchemes.update(schemeId, update);
      const schemes = await getTimeSchemes();
      await setTimeSchemes(schemes);
    } catch (cause) {
      captureError(new Error("Failed to update Time Scheme", { cause }), {
        severity: "error",
        statusCode: 5000,
      });
    }
  });

  return { syncTimeSchemes, createTimeScheme, updateTimeScheme, reloadTimeSchemes };
};
